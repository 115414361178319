class HeaderMenu extends HTMLDetailsElement {
    constructor() {
        super();

        // Ensure internal methods have correct context
        this.setDisabled = this.setDisabled.bind(this);
        this.handleBlur = this.handleBlur.bind(this);
        this.handleActive = this.handleActive.bind(this);
        this.handleSummaryClose = this.handleSummaryClose.bind(this);
        this.handleSummaryOpen = this.handleSummaryOpen.bind(this);
        this.handleMenuOpen = this.handleMenuOpen.bind(this);
        this.close = this.close.bind(this);

        this.$summary = this.querySelector("summary");
        this.$menu = this.querySelector(".gpas-header-menu__body");

        this.addEventListener("blur", this.handleBlur, true);
        this.addEventListener("click", this.handleActive, true);
        this.$summary.addEventListener("mouseenter", this.handleSummaryOpen);
        this.$summary.addEventListener("mouseleave", this.handleSummaryClose);
        this.$menu.addEventListener("mouseenter", this.handleMenuOpen);
        this.$menu.addEventListener("mouseleave", this.handleSummaryClose);
    }
    setDisabled(shouldDisable) {
        this.disabled = shouldDisable;
        // Prevent being able to navigate to it with a keyboard.
        if (shouldDisable) {
            this.$summary.setAttribute("disabled", "true");
            this.$summary.setAttribute("aria-disabled", "true");
            this.$summary.setAttribute("tabindex", "-1");
        } else {
            this.$summary.removeAttribute("aria-disabled");
            this.$summary.removeAttribute("disabled");
            this.$summary.removeAttribute("tabindex");
        }
    }
    handleMenuOpen() {
        clearTimeout(this.closeTimeoutId);
    }
    handleSummaryOpen(event) {
        clearTimeout(this.closeTimeoutId);

        if (this.disabled) {
            event.preventDefault();
        } else {
          this.$summary.click();
        }
    }
    handleSummaryClose() {
        this.closeTimeoutId = setTimeout(() => this.close(), 160);
    }
    handleBlur(event) {
        const otherInput = event.relatedTarget;
        // If null then the user didnt move to another focusable element.
        if (otherInput === null) {
            return;
        }
        const hasMovedOutside = !this.contains(event.relatedTarget);
        // If the user has stopped interacting with the menu then close it.
        if (hasMovedOutside) {
            this.close();
        }
    }
    handleActive(event) {
        const { tagName } = event.target;
        if (tagName === "A" || tagName === "BUTTON") {
            this.close();
        }
    }
    close() {
        this.open = false;
    }
}

export default HeaderMenu;
