class ConfirmButton extends HTMLDialogElement {
  constructor() {
    super();

    const idPrefix = this.id.replace('-modal', '');
    const $triggerButton = document.getElementById(idPrefix + "-outer-button");
    const $confirmButton = document.getElementById(idPrefix + "-inner-button-confirm");
    const $cancelButton = document.getElementById(idPrefix + "-inner-button-cancel");

    $triggerButton.addEventListener("click", () => {
      this.showModal();
    });
    $confirmButton.addEventListener("click", () => {
      this.close();
    });
    $cancelButton.addEventListener("click", () => {
      this.close();
    });
  }
}

export default ConfirmButton;
