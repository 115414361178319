import DetailsMenu from "./details-menu.js";

class SelectMenu extends DetailsMenu {
  constructor() {
    super();

    this.setChecked = this.setChecked.bind(this);
    this.reset = this.reset.bind(this);
    this.setIndeterminate = this.setIndeterminate.bind(this);

    this.$checkboxes = Array.from(
      this.querySelectorAll("input[type=checkbox]")
    );
    this.$checkboxes.forEach(($checkbox) => {
      $checkbox.addEventListener("change", this.setIndeterminate);
    });
  }
  reset() {
    this.$checkboxes.forEach(($checkbox) => {
      $checkbox.checked = false;
      $checkbox.indeterminate = false;
    });
  }
  setIndeterminate() {
    const indeterminate = this.$checkboxes
      .filter(($checkboxes) => $checkboxes.indeterminate)
      .map(($checkboxes) => $checkboxes.value)
      .join(",");

    const isIndeterminate = this.$checkboxes.some(
      ($checkbox) => $checkbox.indeterminate
    );

    const $indeterminateInput = this.querySelector("[name^='indeterminate']");
    $indeterminateInput.value = isIndeterminate ? indeterminate : false;
  }
  setChecked(state) {
    this.reset();

    Object.keys(state).forEach((name) => {
      const input = this.querySelector(`[value='${name}']`);
      input.checked = state[name].checked;
      input.indeterminate = state[name].indeterminate;
    });

    this.setIndeterminate();
  }
}

export default SelectMenu;
