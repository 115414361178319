class DetailsMenu extends HTMLDetailsElement {
    constructor() {
        super();

        // Ensure internal methods have correct context
        this.setDisabled = this.setDisabled.bind(this);
        this.handleBlur = this.handleBlur.bind(this);
        this.handleActive = this.handleActive.bind(this);
        this.handleDocumentClick = this.handleDocumentClick.bind(this);
        this.handleSummaryClick = this.handleSummaryClick.bind(this);
        this.close = this.close.bind(this);

        this.$summary = this.querySelector("summary");

        this.addEventListener("blur", this.handleBlur, true);
        this.addEventListener("click", this.handleActive, true);
        this.$summary.addEventListener("click", this.handleSummaryClick);
        document.addEventListener("click", this.handleDocumentClick);
    }
    setDisabled(shouldDisable) {
        this.disabled = shouldDisable;
        // Prevent being able to navigate to it with a keyboard.
        if (shouldDisable) {
            this.$summary.setAttribute("disabled", "true");
            this.$summary.setAttribute("aria-disabled", "true");
            this.$summary.setAttribute("tabindex", "-1");
        } else {
            this.$summary.removeAttribute("aria-disabled");
            this.$summary.removeAttribute("disabled");
            this.$summary.removeAttribute("tabindex");
        }
    }
    handleSummaryClick(event) {
        if (this.disabled) {
            event.preventDefault();
        }
    }
    handleDocumentClick(event) {
        const hasMovedOutside = !this.contains(event.target);
        // If the user has stopped interacting with the menu then close it.
        if (hasMovedOutside) {
            this.close();
        }
    }
    handleBlur(event) {
        const otherInput = event.relatedTarget;
        // If null then the user didnt move to another focusable element.
        if (otherInput === null) {
            return;
        }
        const hasMovedOutside = !this.contains(event.relatedTarget);
        // If the user has stopped interacting with the menu then close it.
        if (hasMovedOutside) {
            this.close();
        }
    }
    handleActive(event) {
        const { tagName } = event.target;
        if (tagName === "A" || tagName === "BUTTON") {
            this.close();
        }
    }
    close() {
        this.open = false;
    }
}

export default DetailsMenu;
